import React from 'react';
import { Helmet } from 'rnd-helmet';
import {injectIntl} from "react-intl";
import { useDataLayer } from "dataLayerHelper";
import SubMenu from "@Components/SubMenu";

function RegisterConfirmation({intl, staticContext}) {
  const { locale } = intl;

  // Generate dataLayer for the page.
  useDataLayer(locale, staticContext, "registration");

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({id: 'Breadcrumb.RegisterConfirmation'})} | {intl.formatMessage({id: 'Breadcrumb.Dashboard'})}</title>
        <header className="header header--text bg-variant-brand-quaternary"/>
      </Helmet>
      <div className="header header--text bg-variant-brand-quaternary">
        <div className="header__wrapper wrapper">
          <SubMenu />
          <div className="header__content header__content--l content-block has-breadcrumbs">
            <h1 className="content-block__title">
              <span className="content-block__title-top">{intl.formatMessage({id: 'RegisterConfirmation.Header.Title.Top'})}</span>
            </h1>
            <div className="content-block__split">
              <div className="content-block__split-text">
                <p>{intl.formatMessage({id: 'RegisterConfirmation.Header.Title.Bottom'})}</p>
              </div>
            </div>
          </div>
          <div className="header__media media-block">
            <img src="/myrandstad-app/assets/image/icons/HandMoney_illustration_UseBackgroundBlue_RGB.svg" alt="" />
          </div>
        </div>
      </div>
      <div className="block">
        <div className="block__wrapper wrapper">
          <div className="block__header">
            <h2 className="block__title">{intl.formatMessage({id: 'RegisterConfirmation.Body.Left.Title'})}</h2></div>
          <div className="block__content">
            <p>{intl.formatMessage({id: 'RegisterConfirmation.Body.Right.Text'})}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default injectIntl(RegisterConfirmation);
